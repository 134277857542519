
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.box {
  position: relative;

  .tooltip {
    position: absolute;
    background-color: rgb(var(--jet));
    min-width: 115px;
    padding: 8px 12px;
    border-radius: 6px;
    color: rgb(var(--white));
    font-size: var(--font-size-xs);
    text-align: center;
    z-index: 1;
    white-space: nowrap;
    display: none;

    &.open {
      display: inline-block;
      &:not(.disableAnimation) {
        animation: fadeIn 0.3s ease-in forwards;
      }
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
    }

    &.pointBottom::before {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid var(--arrow-border);
    }
    &.pointBottom::after {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid var(--arrow-color);
    }
    &.pointTop::before {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid var(--arrow-border);
    }
    &.pointTop::after {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid var(--arrow-color);
    }
    &.pointLeft::before {
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 7px solid var(--arrow-border);
    }
    &.pointLeft::after {
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid var(--arrow-color);
    }
    &.pointRight::before {
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid var(--arrow-border);
    }
    &.pointRight::after {
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid var(--arrow-color);
    }
    &.top {
      left: 50%;
      bottom: calc(100% + 6px);
      transform: translateX(-50%);

      &::before,
      &::after {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &.top-left {
      bottom: calc(100% + 6px);
      left: -12px;

      &::before {
        top: 100%;
        left: 11px;
      }
      &::after {
        top: 100%;
        left: 12px;
      }
    }
    &.top-right {
      bottom: calc(100% + 6px);
      right: -12px;

      &::before {
        top: 100%;
        right: 11px;
      }

      &::after {
        top: 100%;
        right: 12px;
      }
    }
    &.bottom {
      top: calc(100% + 6px);
      left: 50%;
      transform: translateX(-50%);

      &::before,
      &::after {
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &.bottom-left {
      top: calc(100% + 6px);
      left: -12px;

      &::before {
        bottom: 100%;
        left: 11px;
      }
      &::after {
        bottom: 100%;
        left: 12px;
      }
    }
    &.bottom-right {
      top: calc(100% + 6px);
      right: -12px;

      &::before {
        bottom: 100%;
        right: 11px;
      }
      &::after {
        bottom: 100%;
        right: 12px;
      }
    }
    &.right {
      left: calc(100% + 12px);
      top: 50%;
      transform: translateY(-50%);

      &::before {
        top: 50%;
        left: -7px;
        transform: translateY(-50%);
      }
      &::after {
        top: 50%;
        left: -6px;
        transform: translateY(-50%);
      }
    }
    &.left {
      right: calc(100% + 12px);
      top: 50%;
      transform: translateY(-50%);

      &::before {
        top: 50%;
        right: -7px;
        transform: translateY(-50%);
      }
      &::after {
        top: 50%;
        right: -6px;
        transform: translateY(-50%);
      }
    }
    &.closeButtonPadding {
      padding-left: 18px;
    }
  }
}
