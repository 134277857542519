
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.noteBox {
  width: 200px;
  padding: 20px;
  text-align: center;

  .title {
    display: block;
    font-size: var(--font-size-m);
  }

  .text {
    font-size: var(--font-size-xs);
  }
}
