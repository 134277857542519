
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.circleArea {
  font-weight: var(--font-weight-regular);
  border-radius: 50%;
  text-align: center;
  background: rgb(var(--isabelline));
  border: 1px solid rgb(var(--pastelGrey));

  &.primary {
    &.loggedIn {
      background: rgb(var(--linen));
      border: 1px solid rgb(var(--safetyOrange));
      color: rgb(var(--safetyOrange));
    }
  }

  &.yad1 {
    &.loggedIn {
      border: 0;
      background: rgb(var(--aliceBlue));
      color: rgb(var(--azure));
    }
  }

  @include desktop {
    margin: auto;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
  }

  @include mobile {
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
  }
}
