
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.hasFrame {
  position: relative;
  background-color: rgb(var(--white));
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  padding: 2px;
  border: 1px solid rgb(var(--pastelGrey));

  &:hover {
    border: 1px solid rgb(var(--spanishGrey));
  }
}

.likeButton {
  @include mobile {
    padding: 8px;
    margin: -8px;
  }
}

.likeHover {
  @include desktop {
    &:hover {
      svg > g > :last-child path {
        // ^ heart border vector only
        fill: #f65b5b;
      }
    }
  }
}

.defaultIconClassName {
  @include iconLarge();
}
