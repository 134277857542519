
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/styles';
@import '@y2/mango/fonts';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: rgb(var(--jet));

  .footer {
    margin-top: auto;
  }
}

[hidden],
.hidden {
  display: none !important;
}

.mobile-only {
  @include desktop {
    display: none !important;
  }
}

.desktop-only {
  @include mobile {
    display: none !important;
  }
}

.no-overflow {
  overflow: hidden;
}
