
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';

.option {
  position: relative;
  align-items: center;
  display: flex;
  line-height: 50px;

  width: 100%;

  &:hover,
  &:focus,
  &:focus-within {
    background-color: rgb(var(--snow));
  }

  .count {
    padding: 0 5px;
    font-size: 10px;
    font-weight: var(--font-weight-regular);
    line-height: 15px;
    text-align: center;
    border-radius: 10px;
    margin-right: 5px;
    color: rgb(var(--white));
    background-color: rgb(var(--safetyOrange));
  }

  .iconBox {
    position: relative;
    line-height: 0;

    .count {
      margin: unset;
      position: absolute;
      top: -3px;
      right: -6px;
    }
  }

  .text {
    font-weight: var(--font-weight-light);
    color: rgb(var(--jet));
  }

  @include desktop {
    padding: 0 20px 0 30px;

    .text {
      white-space: nowrap;
    }
  }

  @include mobile {
    &.logout,
    &.hoisted {
      line-height: normal;
      margin-top: 5px;
      font-weight: var(--font-weight-light);
    }
    &.logout {
      position: absolute;
      top: 0;
      left: 0;
      margin: 12px;
      display: block;
      width: auto;
      font-size: var(--font-size-xs);

      .text {
        color: rgb(var(--spanishGrey));
      }
    }
    &.hoisted {
      .text {
        color: rgb(var(--jet));
        font-size: var(--font-size-xs);
        text-decoration: underline;
      }
    }
  }
}
