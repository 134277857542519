
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.box {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100%;

  .dropContent {
    display: none;
    position: absolute;
    top: 100%;
    z-index: var(--floaters-index);

    &.open {
      @include desktop {
        display: block;
      }
    }
  }
}
