
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.close {
  line-height: 0;
  position: absolute;
  left: 4px;
  top: 4px;
  // for accessibility a button needs to be at-least 40x40
  padding: 10px;
  margin: -10px;

  .icon {
    @include iconXS(rgb(var(--isabelline)));
  }
}
