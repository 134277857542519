
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.box {
  color: rgb(var(--jet));
  list-style-type: none;
  background-color: rgb(var(--white));
  box-shadow:
    0 3px 5px 0 rgb(0 0 0 / 20%),
    0 1px 18px 0 rgb(0 0 0 / 12%),
    0 6px 10px 0 rgb(0 0 0 / 14%);
}
