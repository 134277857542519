
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';

.optionIcon {
  flex: none;
  margin-left: 10px;
  fill: rgb(var(--jet));
  font-size: var(--font-size-xl);

  @include mobile {
    &.removeFromMobile {
      display: none;
    }
  }
}

.inlineButton {
  padding: 0 10px;
  min-height: 45px;

  .icon {
    @include iconLarge(rgb(var(--jet)));
  }
}
