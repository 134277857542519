
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.categoryItem {
  @include desktop {
    .categoryNameContainer {
      pointer-events: none;
    }
  }

  @include mobile {
    padding: 16px 0;
    border-top: 1px solid rgb(var(--graniteGrey));

    &:last-of-type {
      border-bottom: 1px solid rgb(var(--graniteGrey));
    }

    .categoryNameContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrowIcon {
        margin-right: auto;
        fill: rgb(var(--white));
        transform: rotate(90deg);
        transition: 0.3s;
      }
    }

    &.isCollapsed .arrowIcon {
      transform: unset;
    }
  }
}

.categoryItemHeading {
  color: rgb(var(--white));
  font-size: var(--font-size-s);

  @include desktop {
    margin-bottom: 10px;
  }

  @include mobile {
    pointer-events: none;
    margin-bottom: 0;
    color: rgb(var(--white));
    font-size: var(--font-size-m);
  }
}

.categoriesList {
  display: flex;

  @include desktop {
    justify-content: space-between;
  }

  @include mobile {
    flex-direction: column;
  }
}

.subCategoryList {
  color: rgb(var(--pastelGrey));

  @include mobile {
    display: none;

    &.isCollapsed {
      display: block;
      margin-top: 10px;
    }
  }
}
