
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.img {
  border-radius: 50%;
  height: 100%;
}

.userInitials {
  font-weight: var(--font-weight-regular);

  @include desktop {
    align-self: center;
    font-size: 15px;
  }
  @include mobile {
    align-self: center;
    font-size: 20px;
  }
}

.icon {
  height: 100%;
  margin: auto;
  fill: rgb(var(--spanishGrey));

  @include desktop {
    font-size: 25px;
    font-weight: var(--font-weight-regular);
  }

  @include mobile {
    font-size: 35px;
    font-weight: var(--font-weight-medium);
  }
}
