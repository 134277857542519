
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';

.option-item {
  @include desktop {
    flex-grow: 1;
  }

  @include mobile {
    order: 4;
    flex-basis: 100%;
  }

  &.is-mobile-only {
    @include desktop {
      display: none;
    }
  }
}
