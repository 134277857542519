
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.headerLink {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 20px;
  cursor: pointer;

  &:hover {
    background-color: rgb(var(--snow));
  }

  @include mobile {
    padding: 12px;
  }

  .icon {
    @include desktop {
      display: none;
    }
  }

  .badge {
    color: rgb(var(--white));
    background-color: rgb(var(--graniteGrey));
    border-radius: 50px;
    height: 16px;
    font-size: var(--font-size-xxxs);
    font-weight: var(--font-weight-medium);
    padding: 2px 4px;
  }
}
