
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.footer {
  background-color: rgb(var(--jet));
  color: rgb(var(--pastelGrey));

  @include desktop {
    padding: 50px 40px 10px;
  }

  @include mobile {
    padding: 24px;
  }
}

.contactUsWrapper {
  display: flex;
  justify-content: center;

  @include desktop {
    margin: 10px;
    padding-bottom: 50px;
  }

  @include mobile {
    margin: 16px;

    padding-bottom: 24px;
  }
}
