
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-s);
}

.infoCol {
  text-align: right;
  width: 50%;
  padding: 10px;

  .row {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.description {
      font-size: var(--font-size-xs);
    }
  }
}

.priceCol {
  width: 25%;
  padding: 10px;
  align-items: flex-end;
}
