
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';

.disconnect-row {
  flex-grow: 1;
}

.options-list {
  @include mobile {
    display: contents;

    li {
      padding-right: 12px;
    }
  }

  @include desktop {
    display: flex;
    flex-direction: column;
  }
}

.user-content-group {
  @include mobile {
    order: 2;
    flex-basis: 75%;
    align-self: start;
    margin-top: 56px;
  }

  @include desktop {
    display: flex;
    flex-direction: column;
  }
}

.disconnect-row {
  @include mobile {
    order: 1;
    flex: 1 1 100%;
  }

  @include desktop {
    border-top: 1px solid rgb(var(--isabelline));
  }
}

.fullNameRow {
  display: block;
  order: 2;
  width: 100%;
  max-width: 205px;
  line-height: normal;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium);
  @include trim-table();

  @include desktop {
    display: none;
  }
}
