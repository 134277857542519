
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.subCategoryItem {
  margin-bottom: 6px;
  font-weight: var(--font-weight-regular);
  line-height: 1.875;
  margin: 0;

  @include desktop {
    font-size: var(--font-size-xs);
  }

  @include mobile {
    font-size: var(--font-size-s);
  }
}
