
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.profileBoxText {
  color: rgb(var(--jet));
  font-size: var(--font-size-s);

  @include desktop {
    margin-right: 5px;
    font-weight: 400;
  }

  @include mobile {
    margin-top: 10px;
    font-weight: var(--font-weight-medium);
  }

  &.wideDesktop {
    display: none;

    @include wideDesktop {
      display: inline;
    }
  }
}
