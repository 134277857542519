
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.linkRow {
  display: flex;
  width: 100%;

  .link {
    padding: 10px 15px;
    margin: 10px auto;
    border-radius: 2px;
    color: rgb(var(--main-color));
    border: 1px solid rgb(var(--main-color));
    min-width: 165px;
    font-size: var(--font-size-xs);
    font-weight: 500;
    text-align: center;

    &:hover {
      background-color: rgb(var(--main-color));
      color: rgb(var(--white));
    }
  }
}
