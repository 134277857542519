
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.box {
  position: relative;
  border-bottom: 1px solid rgb(var(--isabelline));
  min-height: 85px;
  width: 460px;
  max-width: 100%;
  cursor: pointer;

  &:hover {
    box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 15%);
    z-index: 1;

    .imgContainer {
      img {
        transform: scale(1.05);
      }
    }
  }

  .imgContainer {
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 110px;
    height: 66px;
    border-radius: 3px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: 0.15s ease-out;
    }
  }

  .likePosition {
    position: absolute;
    right: 7px;
    top: 7px;
    z-index: 2;
  }

  .link {
    padding: 10px;
    display: flex;
    flex-direction: row;
  }
}
