
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.categoriesListNav {
  max-width: 1200px;
  margin-inline: auto;
}

.categoriesList {
  display: flex;

  @include desktop {
    justify-content: space-between;
    padding-bottom: 40px;
    border-bottom: 1px solid rgb(var(--graniteGrey));
  }

  @include mobile {
    flex-direction: column;
  }
}
