
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.badge {
  display: inline-block;

  &:not(.numeric) {
    border-radius: 4px;
    font-weight: 500;

    &.small {
      padding: 2px 4px 3px;
    }
    &.large {
      padding: 3px 6px 4px;
    }
  }

  &.numeric {
    border-radius: 17px;
    font-weight: 400;
    text-align: center;

    &.small {
      min-width: 14px;
      padding: 2px 4px;
    }
    &.large {
      min-width: 18px;
      padding: 2px 4px;
    }
  }

  &.dot {
    min-width: 8px;
    min-height: 8px;
  }

  &.small {
    font-size: var(--font-size-xxxs);
    line-height: var(--font-size-xxxs);
  }

  &.large {
    font-size: var(--font-size-xxs);
    line-height: var(--font-size-xxs);
  }

  &.default {
    background-color: rgb(var(--spanishGrey));
    color: rgb(var(--white));
  }
  &.green {
    background-color: rgb(var(--caribbeanGreen));
    color: rgb(var(--white));
  }
  &.light-green {
    background-color: rgb(var(--azureishWhite));
    color: rgb(var(--caribbeanGreen));
  }
  &.blue {
    background-color: rgb(var(--azure));
    color: rgb(var(--white));
  }
  &.deep-blue {
    background-color: rgb(var(--bleuDeFrance));
    color: rgb(var(--white));
  }
  &.light-blue {
    background-color: rgb(var(--aliceBlue));
    color: rgb(var(--azure));
  }
  &.lavender {
    background-color: rgb(var(--paleLavender));
    color: rgb(var(--darkOrchid));
  }
  &.orange {
    background-color: rgb(var(--safetyOrange));
    color: rgb(var(--white));
  }
  &.light-orange {
    background-color: rgb(var(--linen));
    color: rgb(var(--safetyOrange));
  }
  &.gray {
    background-color: rgb(var(--isabelline));
    color: rgb(var(--jet));
  }
  &.red {
    background-color: rgb(var(--rouge));
    color: rgb(var(--white));
  }
  &.transparent-jet {
    background-color: rgb(var(--jet), 0.6);
    color: rgb(var(--white));
  }
  &.deep-red {
    background-color: rgb(var(--lightPink));
    color: rgb(var(--deepRed));
  }
}
